import * as auth from 'external/domains/auth/passwordless/client';
import { AuthenticatedVar } from 'shared/domains/apollo/generated/types';
import { useAuthenticatedState } from 'shared/domains/apollo/utils/cacheVars';
import { RTCPleditor } from '../RTCPleditor';
import { useGetExternalUser } from '../hooks';
import { EXTERNAL_COMPOSED_PLUGINS } from '../plugins';
export function ExternalComposedRTCPleditor() {
    const { currentUser } = useGetExternalUser();
    const expiredSession = auth.expiredSession();
    const authState = useAuthenticatedState();
    const unauthorized = authState !== AuthenticatedVar.Authenticated;
    const isSessionExpired = expiredSession || unauthorized;
    const toolbarItems = ['<->', 'blockComment', '|', 'toggleComments'];
    const balloonToolbarItems = ['blockComment'];
    return (<RTCPleditor editorName="ExternalComposedRTCPleditor" documentType="composed" toolbarItems={toolbarItems} balloonToolbarItems={balloonToolbarItems} currentUser={currentUser} clientPlugins={EXTERNAL_COMPOSED_PLUGINS} isSessionExpired={isSessionExpired} restricted/>);
}
