import * as auth from 'external/domains/auth/passwordless/client';
import { AuthenticatedVar } from 'shared/domains/apollo/generated/types';
import { useAuthenticatedState } from 'shared/domains/apollo/utils/cacheVars';
import { useDocumentAttachments } from 'shared/domains/documents';
import { PleditorError } from '../../components/PleditorError';
import { PleditorLoader } from '../../components/PleditorLoader';
import { RTCPleditor } from '../RTCPleditor';
import { useGetDocumentFeatures, useGetExternalUser } from '../hooks';
import { EXTERNAL_FREE_TEXT_PLUGINS } from '../plugins';
export function ExternalFreeTextRTCPleditor() {
    const { currentUser } = useGetExternalUser();
    const { attachments, loading: loadingAttachments, error: attachmentsError, } = useDocumentAttachments();
    const expiredSession = auth.expiredSession();
    const authState = useAuthenticatedState();
    const unauthorized = authState !== AuthenticatedVar.Authenticated;
    const isSessionExpired = expiredSession || unauthorized;
    const { documentFeatures, loading: loadingFeatures, error: featuresError, } = useGetDocumentFeatures();
    const loading = loadingFeatures || loadingAttachments;
    const error = featuresError || attachmentsError;
    if (loading) {
        return <PleditorLoader />;
    }
    if (error) {
        return <PleditorError />;
    }
    const toolbarItems = ['<->', 'inlineComment', '|', 'toggleComments'];
    const balloonToolbarItems = ['inlineComment'];
    return (<RTCPleditor editorName="ExternalFreeTextRTCPleditor" documentType="freetext" attachments={attachments} toolbarItems={toolbarItems} balloonToolbarItems={balloonToolbarItems} currentUser={currentUser} featureAvailability={documentFeatures} clientPlugins={EXTERNAL_FREE_TEXT_PLUGINS} isSessionExpired={isSessionExpired}/>);
}
